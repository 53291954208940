export const msalConfig = {
  auth: {
    clientId: "f2faa9c0-fee8-422c-9375-6c0dab8e8ac6",
    authority: "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com",
    knownAuthorities: ["devbicab2c.b2clogin.com"],
    redirectUri: "https://mybicadev.bica.org.bw",
    postLogoutRedirectUri: "https://mybicadev.bica.org.bw/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const forgotPasswordRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_signup",
};

export const resetPasswordRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_passReset",
};

export const editProfileRquest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_editProfile",
};
export const signupRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_registration",
};

export const loginRequest = {
  authority:
  "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_signup",
  scopes: [
    "openid",
    "profile",
    "https://devbicab2c.b2clogin.com/api/demo.read",
  ],
};

export const logoutRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_signup",
};

export const tokenRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_signup",
  scopes: [
    "openid",
    "profile",
    "https://devbicab2c.b2clogin.com/api/demo.read",
  ],
};

export const tokenRequestRegistration = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_registration",
  scopes: [
    "openid",
    "profile",
    "https://devbicab2c.b2clogin.com/api/demo.read",
  ],
};

export const edittokenRequest = {
  authority:
    "https://devbicab2c.b2clogin.com/devbicab2c.onmicrosoft.com/B2C_1_editProfile",
  scopes: [
    "openid",
    "profile",
    "https://devbicab2c.b2clogin.com/api/demo.read",
  ],
};

export const graphConfig = {
  // graphMeEndpoint: "https://graph.microsoft.com/beta/me",
  graphMeEndpoint: "https://graph.windows.net/me?api-version=1.6",
};
