import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema_nonaat } from "app/pages/firmmembership/model/uiSchema_nonaat";
import { uiSchema_nonaudit } from "app/pages/firmmembership/model/uiSchema_nonaudit";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function FirmMembership(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const URL = window.location.href;
  const [urlParam, seturlParam] = useState({
    formName: window.location.href.includes("aat")
      ? "firmMembership_nonaudit"
      : "firmMembership_nonaat",
    subId: "",
  });
  const options = {
    formTitle: "Firm Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: window.location.href.includes("aat")
      ? uiSchema_nonaat
      : uiSchema_nonaudit,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: true,
    hasPayment: true,
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    const formName = window.location.href.includes("firmMembership_nonaat")
      ? "firmMembership_nonaat": "firmMembership_nonaudit";
      
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
    } else {
      setisNew(true);
    }
    seturlParam((urlParam) => ({ ...urlParam, formName }));
  }, [URL]);

  useEffect(() => {
    console.log("url parameters" + JSON.stringify(urlParam));
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>
                    {urlParam.formName !== "firmMembership_nonaudit"
                      ? "Firm Application (ACCTECH)"
                      : "Firm Application (Professional)"}
                  </h1>
                  <p style={{ fontWeight: "700", marginTop: "2rem" }}>
                    Kindly NOTE that you will be required to attach the
                    following documents during the application.
                  </p>
                  <ul>
                    <li>Copy of professional indemnity insurance</li>
                    <li>
                      Copy of written undertaking by the firm and its partners
                      to be bound by the disciplinary provisions of the rules of
                      the Institute individually
                    </li>
                    {urlParam.formName !== "firmMembership_nonaudit" && (
                      <li>Certified copy of Omang/Passport</li>
                    )}
                    <li>
                      Certified copy of registration or certificate of
                      incorporation
                    </li>
                    <li>
                      Copy of resolution authorizing the signatory to sign on
                      behalf of the firm
                    </li>
                    <li>Copy of shareholders certificate </li>
                    <li>
                      Curriculum Vitae detailing experience and qualifications{" "}
                    </li>
                    <li>Quality control manual</li>
                    <li>Police Clearance Certificates</li>
                    <li>Appointment of Compliance Officer, AML Policy & Procedures and Training Program</li>
                    <li>Registration on the Financial Intelligence Agency (FIA)Goal platform reporting of suspicious and Transaction Reports</li>
                  </ul>

                  <br />
                  <p style={{ marginLeft: "1.5rem" }}>
                    Supported file types:- PDF, JPG, JPEG, PNG{" "}
                  </p>

                  <br />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={startApp}
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                  <div>
                    <Sonicwebform options={options} />
                  </div>
                )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(FirmMembership);
