export const config = {
  teleportal: {
    env: "https://bc-azgb-tp-dvfun01.azurewebsites.net",
    file: {
      Create: "/api/CreateFileAsync",
      Delete: "/api/DeleteFileAsync",
      get: "/api/GetFileAsync",
      list: "/api/GetAllAsync",
      delete: "/api/DeleteRecordAsync",
    },
    lookup: {
      get: "/api/GetAllAsync",
    },
    submitForm: {
      Create: "/api/CreateRecordAsync",
      Update: "/api/UpdateRecordAsync",
      get: "/api/GetAllAsync",
      getId: "/api/GetRecordByIdAsync",
      delete: "/api/DeleteRecordAsync",
    },
    profile: {
      get: "/api/ProfileAsync",
    },
    sonicForm: {
      get: "/api/GetFormSchemaAsync",
    },
    preferences: {
      get: "/api/GetPreferencesAsync",
      update: "/api/UpdatePreferencesAsync",
    },
    letter: {
      create: "/api/CaseCreationAsync",
    },
    order: {
      create: "/api/OrderCreationAsync",
    },
    custom: {
      fetch: "/api/ApplicationFormListAsync",
    },
    events: {
      getAllEventList: "/api/FunctionExecuteAsync",
    }
  },
  entitlement: {
    env: "https://bc-azgb-etl-dvfun01.azurewebsites.net",
    entitle: {
      getContactEntitle:
        "/api/GetContactEntitlementAsync?",
      getEntitle:
        "/api/GetEntitlementAsync?",
    },
  },
  publicAPI: {
    firmDirectory:
      "https://bc-azgb-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=account",
    memberDirectory:
      "https://bc-azgb-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=contact",
  },
  sonicwebform: {
    env: "https://bc-azgb-swf-dvfun02.azurewebsites.net",
    getFormschema: "/api/GetFormSchemaAsync",
    createFormsubmit: "/api/CreateFormSubmissionAsync",
    getFormsubmit: "/api/GetFormSubmissionAsync",
    cancelFormsubmit: "/api/CancelFormSubmissionAsync",
  },
  payment: {
    endPoint: "https://core3.directpay.online/vcs/pay",
    hashKey: "488d8154373c6f74634ce078b3aae5bc",
    terminalId: "9559",
    paymentSuccessHandlerAsyncUrl: "https://bc-azgb-tp-dvfun03.azurewebsites.net/api/PaymentSuccessHandlerAsync?code=WSuRk8cYa2wG8ql1URqXrt5WQevrx5ARFhGBSMiqvCzFoP7gl5dozA==",
    paymentFailureHandlerAsyncUrl: "https://bc-azgb-tp-dvfun03.azurewebsites.net/api/PaymentFailureHandlerAsync?code=6FLlQr6g7ffIaiESyvj3ODJMHbH5cwIDm/HxD6HU1lAwEq6Q8i4PrA==",
    env: "https://bc-azgb-tp-dvfun03.azurewebsites.net",
    orderDetails: "/api/OrderDetailsAsync",
    payHandler: "/api/paymenthandlerasync",
  },
  blob: {
    env: "https://bcazgbtpteasa02.blob.core.windows.net",
  }
};
